import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Image, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppUtils } from 'utils';
import { profilePicture } from 'assets/images';
import { engage, empower, connect, volunteer, logo } from 'assets/icons';
import { AppConstants, ServerConstants } from 'constant';
import LoginModal from 'screens/modal/login';
import SignUpModal from 'screens/modal/sign-up';
import ForgetPasswordModal from 'screens/modal/forget-password';
import ForgetMyPasswordModal from 'screens/profile/forget-password';
import ChangeProfilePictureModal from 'screens/modal/profile-picture';
import { useFetch } from 'hooks/fetch';

const { PATH_HOME, PATH_NEWS, PATH_CONNECT, PATH_EMPOWER, PATH_ENGAGE, PATH_COMMUNITY, PATH_FORUM, PATH_VOLUNTEERING, PATH_OPPORTUNITIES, PATH_FAQ, PATH_PROFILE, USER_DATA, DEFAULT_LANG } = AppConstants;
const modals = { LOGIN: 1, SIGN_UP: 2, FORGET_PASSWORD: 3, PROFILE_PICTURE: 4 }
const { API_METHOD_POST, API_URL_ORGANIZATION, API_URL_MEMBER, API_URL_VOLUNTEER } = ServerConstants;

const languages = [
  { "icon": '🇬🇧', "name": 'en' },
  { "icon": '🇪🇸', "name": 'es' },
  { "icon": '🇬🇷', "name": 'gr' },
  { "icon": '🇸🇮', "name": 'sl' },
  { "icon": '🇷🇸', "name": 'sr' },
  { "icon": '🇫🇷', "name": 'fr' },
];

const Header = () => {

  const { t } = useTranslation();
  const params = window.location.search ? window.location.search.replace('?', '') : '';

  const changeLanguageFetch = useFetch(API_METHOD_POST);
  const [selectedLanguage, setSelectedLanguage] = useState(AppUtils.getLanguage());
  const [showModal, setShowModal] = useState(params && params.includes('login') ? modals.LOGIN : undefined);
  const loggedUser = useRef(AppUtils.getLoggedUser());

  const dismissModal = useCallback(() => setShowModal(undefined), []);

  const getUserName = () => {
    if (AppUtils.isUserOrganization()) {
      return loggedUser.current.organizationName;
    } else if (AppUtils.isUserMember()) {
      return loggedUser.current.fullName;
    } else if (AppUtils.isUserVolunteer()) {
      return loggedUser.current.fullName;
    }
    return "???"
  };

  const logoutUser = useCallback(() => {
    AppUtils.logOut();
    window.location.href = '/';
  }, []);

  useEffect(() => {
    const prevSelected = AppUtils.getLanguage();
    if (!selectedLanguage || prevSelected === selectedLanguage) {
      return;
    }
    if (!loggedUser.current) {
      localStorage.setItem(DEFAULT_LANG, selectedLanguage);
      AppUtils.changeLanguage();
      return;
    }
    const path = "/" + loggedUser.current.id + "/language/" + selectedLanguage;
    if (AppUtils.isUserOrganization()) {
      changeLanguageFetch.setFetchUrl(API_URL_ORGANIZATION + path);
      changeLanguageFetch.setBody({});
    } else if (AppUtils.isUserMember()) {
      changeLanguageFetch.setFetchUrl(API_URL_MEMBER + path);
      changeLanguageFetch.setBody({});
    } else if (AppUtils.isUserVolunteer()) {
      changeLanguageFetch.setFetchUrl(API_URL_VOLUNTEER + path);
      changeLanguageFetch.setBody({});
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (!changeLanguageFetch.response) {
      return;
    }
    if (changeLanguageFetch.response.status === 200) {
      const mergedData = {
        ...JSON.parse(localStorage.getItem(USER_DATA)),
        language: selectedLanguage,
      };
      localStorage.setItem(USER_DATA, JSON.stringify(mergedData));
      AppUtils.changeLanguage();
    }
  }, [changeLanguageFetch.response]);

  return (
    <>
      <LoginModal modals={modals} t={t} show={showModal === modals.LOGIN} onDismiss={dismissModal} showForgetPasswordModal={() => setShowModal(modals.FORGET_PASSWORD)} showSignUpModal={() => setShowModal(modals.SIGN_UP)} />
      <SignUpModal modals={modals} t={t} show={showModal === modals.SIGN_UP} onDismiss={dismissModal} showLoginModal={() => setShowModal(modals.LOGIN)} />
      {AppUtils.isUserMember() || AppUtils.isUserVolunteer() ?
        <>
          <ForgetMyPasswordModal email={loggedUser.current?.email} onDismiss={dismissModal} show={showModal === modals.FORGET_PASSWORD} t={t} />
          <ChangeProfilePictureModal onDismiss={dismissModal} show={showModal === modals.PROFILE_PICTURE} t={t} />
        </>
        : <ForgetPasswordModal t={t} show={showModal === modals.FORGET_PASSWORD} onDismiss={dismissModal} showLoginModal={() => setShowModal(modals.LOGIN)} />
      }
      <Navbar expand="xl" className="header" sticky="top" >
        <Navbar.Brand href="/">
          <div className="d-flex flex-row align-items-center">
            <Image src={logo} width={73} height={73} />
            <div className="d-flex flex-column align-items-center justify-content-center logo-name">
              <span>Digital</span>
              <span>YOu</span>
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="align-items-center">
            <Nav.Link href={PATH_HOME}>{t('navbar.home')}</Nav.Link>
            <Nav.Link href={PATH_NEWS}>{t('navbar.news')}</Nav.Link>
            <Nav.Link href={PATH_OPPORTUNITIES}>{t('navbar.opportunities')}</Nav.Link>
            <NavDropdown title={t('navbar.functions')} id="functions-nav-dropdown">
              <Nav.Link href={PATH_ENGAGE} className="header-function header-function-engage">
                <Image src={engage} className="header-function-image" />
                <span className="header-function-text">{t('functions.engage.title')}</span>
              </Nav.Link>
              <Nav.Link href={PATH_EMPOWER} className="header-function header-function-empower">
                <Image src={empower} className="header-function-image" />
                <span className="header-function-text">{t('functions.empower.title')}</span>
              </Nav.Link>
              <Nav.Link href={PATH_CONNECT} className="header-function header-function-connect">
                <Image src={connect} className="header-function-image" />
                <span className="header-function-text">{t('functions.connect.title')}</span>
              </Nav.Link>
              <Nav.Link href={PATH_VOLUNTEERING} className="header-function header-function-volunteering">
                <Image src={volunteer} className="header-function-image" />
                <span className="header-function-text">{t('functions.volunteering.title')}</span>
              </Nav.Link>
            </NavDropdown>
            <Nav.Link href={PATH_COMMUNITY}>{t('navbar.community')}</Nav.Link>
            {loggedUser.current && (AppUtils.isUserOrganization() || AppUtils.isUserMember()) ?
              <Nav.Link href={PATH_FORUM}>{t('navbar.forum')}</Nav.Link>
            : undefined}
            <Nav.Link href={PATH_FAQ}>{t('navbar.faq')}</Nav.Link>
            <Nav.Link href="https://e-ngagement.org" target="_blank" rel="noreferrer">{t('navbar.contact')}</Nav.Link>
            {loggedUser.current ?
              <NavDropdown className="header-user-profile" title={<Image src={loggedUser.current?.profilePicture !== '' ? loggedUser.current?.profilePicture : profilePicture} width={48} height={48} />} id="logged-user-nav-dropdown" align={'end'}>
                <Nav.Item className="p-2">
                  <div className="d-flex flex-direction-row align-items-center">
                    <Image src={loggedUser.current?.profilePicture !== '' ? loggedUser.current?.profilePicture : profilePicture} />
                    <h4 className="px-2 m-0">{getUserName()}</h4>
                  </div>
                </Nav.Item>
                <hr className="my-1" />
                {AppUtils.isUserOrganization() ?
                  <>
                    <Nav.Link href={PATH_PROFILE} className="header-function profile" style={{ width: 220 }}>
                      <FontAwesomeIcon icon={'circle-user'} className="header-function-image" style={{ height: 18 }} />
                      <span className="header-function-text" style={{ marginLeft: 8 }}>{t('profile.title')}</span>
                    </Nav.Link>
                  </>
                  : AppUtils.isUserMember() || AppUtils.isUserVolunteer() ?
                    <>
                      <Nav.Link onClick={() => setShowModal(modals.PROFILE_PICTURE)} className="header-function profile" style={{ width: 220 }}>
                        <FontAwesomeIcon icon={'camera'} className="header-function-image" style={{ height: 18 }} />
                        <span className="header-function-text" style={{ marginLeft: 8 }}>{t('profile.picture.change.title')}</span>
                      </Nav.Link>
                      <Nav.Link onClick={() => setShowModal(modals.FORGET_PASSWORD)} className="header-function profile" style={{ width: 220 }}>
                        <FontAwesomeIcon icon={'lock'} className="header-function-image" style={{ height: 18 }} />
                        <span className="header-function-text" style={{ marginLeft: 8 }}>{t('login.forget.password.change')}</span>
                      </Nav.Link>
                    </>
                    : <></>
                }
                <Nav.Link href={'/#'} onClick={logoutUser} className="header-function profile">
                  <FontAwesomeIcon icon={'arrow-right-from-bracket'} className="header-function-image" style={{ height: 18 }} />
                  <span className="header-function-text" style={{ marginLeft: 8 }}>{t('logout')}</span>
                </Nav.Link>
              </NavDropdown>
              :
              <>
                <Button variant="secondary" className="mx-2" onClick={() => setShowModal(modals.LOGIN)}>{t('login.title')}</Button>
                <Button className="mx-2" onClick={() => setShowModal(modals.SIGN_UP)}>{t('signup.title')}</Button>
              </>
            }
            <NavDropdown title={
              <span style={{ fontSize: 20 }}>{selectedLanguage ? languages.find(el => el.name === selectedLanguage)?.icon : '🇺🇸'}</span>
            } className='header-language' align={'end'}>
              {languages.map((el, index) => (
                <Nav.Link key={`lang-${index}`}  className="header-function profile" onClick={() => { document.body.click(); setSelectedLanguage(el.name);}}>
                  <span style={{ fontSize: 20 }}>{el.icon}</span>
                  <span className="header-function-text">{t('language.options.' + el.name)}</span>
                </Nav.Link>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Header;