import { volunteeringVolunteerIllustration3, volunteeringVolunteerImage2 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Image src={volunteeringVolunteerImage2} width={'100%'} height={440} style={{ marginTop: -32, objectFit: 'cover' }} />
      <br/><br/>
      <h2 id="step-1">{t('learning.volunteering.volunteer.3')}</h2>
      <p className='text-end'><i>»{t('learning.volunteering.volunteer.4')}«</i></p>
      <p className='text-end'>- {t('learning.volunteering.volunteer.5')}</p>
      <p>{t('learning.volunteering.volunteer.6')}</p>
      <p>{t('learning.volunteering.volunteer.7')}</p>
      <p>{t('learning.volunteering.volunteer.8')}</p>
      <br/>
      <h4 id="step-1-1" className='text-volunteering'><a href="https://vcla.net/a-short-history-of-volunteering/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.9')}</a></h4>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringVolunteerIllustration3} width={400} height={300} />
        <div className='pl-4'>
          <p>{t('learning.volunteering.volunteer.10')}</p>
          <p>{t('learning.volunteering.volunteer.11')}</p>
          <p>{t('learning.volunteering.volunteer.12')}</p>
        </div>
      </div>
      <p>{t('learning.volunteering.volunteer.13')}</p>
      <p>{t('learning.volunteering.volunteer.14')}</p>
      <p>{t('learning.volunteering.volunteer.15')}</p>
    </>
  )

}

export default VolunteeringLearningScreen2;