import { volunteeringOrganizationPhoto131, volunteeringOrganizationPhoto132 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen7 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-2-2" className='text-volunteering'>{t('learning.volunteering.organization.57')}</h4>

      <div className='d-flex flex-direction-row pb-3'>
        <div className='pr-4'>
          <p>{t('learning.volunteering.organization.58')}</p>
          <p>
          {t('learning.volunteering.organization.59.1')}
          <a href='https://view.genially.com/6656e3120f32ad0014f3d551/interactive-content-test-your-organization-capacities' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.59.2')}</a>
          </p>
          <p>
            {t('learning.volunteering.organization.60.1')}
            <b>{t('learning.volunteering.organization.60.2')}</b>
            {t('learning.volunteering.organization.60.3')}
            <b>{t('learning.volunteering.organization.60.4')}</b>
            {t('learning.volunteering.organization.60.5')}
          </p>
          <p>{t('learning.volunteering.organization.61')}</p>
        </div>
        <Image src={volunteeringOrganizationPhoto131} width={350} height={200} style={{ minWidth: 350, objectFit: 'none' }}/>
      </div>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringOrganizationPhoto132} width={350} height={200} style={{ minWidth: 350, objectFit: 'none' }}/>
        <div className='pl-4'>
          <p>{t('learning.volunteering.organization.62')}</p>
          <p>
            {t('learning.volunteering.organization.63.1')}
            <a href='https://view.genially.com/6656e3120f32ad0014f3d551/interactive-content-test-your-organization-capacities' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.63.2')}</a>
            {t('learning.volunteering.organization.63.3')}
          </p>
        </div>
      </div>
    </>
  )

}

export default VolunteeringLearningScreen7;