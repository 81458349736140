import { volunteeringVolunteerIllustration24, volunteeringVolunteerImage15 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen15 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-5-4" className='text-volunteering'>{t('learning.volunteering.volunteer.102')}</h4>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringVolunteerIllustration24} width={400} height={300} className='mt-3' />
        <div className='pl-4 pt-4 mt-3'>
          <p>{t('learning.volunteering.volunteer.103')}</p>
          <p>{t('learning.volunteering.volunteer.104')}</p>
        </div>
      </div>
      <div className='box mb-5'>
        <i>
          {t('learning.volunteering.volunteer.105.1')}
          <a href="https://www.leadershipiq.com/blogs/leadershipiq/team-player-quiz-what-type-of-team-player-are-you" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.105.2')}</a>
          {t('learning.volunteering.volunteer.105.3')}
        </i>
      </div>
      <Image src={volunteeringVolunteerImage15} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen15;