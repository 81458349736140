import { AppConstants } from 'constant';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { volunteeringVolunteerImage6 } from 'assets/learning';

const { PATH_VOLUNTEERING_LEARNING_VOLUNTEER } = AppConstants;

const VolunteeringLearningScreen7 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-2-3" className='text-volunteering'>{t('learning.volunteering.volunteer.40')}</h4>
      <p>{t('learning.volunteering.volunteer.41')}</p>
      <h4 id="step-2-3" className='text-volunteering'>{t('learning.volunteering.volunteer.42')}</h4>
      <ul>
        <li>{t('learning.volunteering.volunteer.43.1')}</li>
        <li>{t('learning.volunteering.volunteer.43.2')}</li>
        <li>{t('learning.volunteering.volunteer.43.3')}</li>
        <li>{t('learning.volunteering.volunteer.43.4')}</li>
        <li>{t('learning.volunteering.volunteer.43.5')}</li>
        <li>{t('learning.volunteering.volunteer.43.6')}</li>
      </ul>
      <div className='text-center mt-5'>
        <button onClick={() => window.open(PATH_VOLUNTEERING_LEARNING_VOLUNTEER + '/simulation-1', '_blank')} className='btn btn-volunteering text-white'>{t('learning.volunteering.volunteer.simulation.1')}</button>
      </div>
      <br/>
      <br/>
      <Image src={volunteeringVolunteerImage6} width={'100%'} height={400} style={{ objectFit: 'cover'}} />
    </>
  )

}

export default VolunteeringLearningScreen7;