import { volunteeringOrganizationPhoto11, volunteeringOrganizationPhoto12 } from 'assets/learning';
import { AppConstants } from 'constant';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const { PATH_VOLUNTEERING_LEARNING_ORGANIZATION } = AppConstants;

const VolunteeringLearningScreen6 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32, marginLeft: -16, marginRight: -16 }}>
        <Image src={volunteeringOrganizationPhoto11} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br />
      <h2 id="step-2">{t('learning.volunteering.organization.45')}</h2>
      <h4 id="step-2-1" className='text-volunteering'>{t('learning.volunteering.organization.46')}</h4>

      <p>{t('learning.volunteering.organization.47')}</p>
      <p>{t('learning.volunteering.organization.48')}</p>
      <p>
        {t('learning.volunteering.organization.49.1')}
        <b>{t('learning.volunteering.organization.49.2')}</b>
        {t('learning.volunteering.organization.49.3')}
        <a href='https://www.youtube.com/watch?v=zpzZumZCdWA' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.49.4')}</a>
      </p>
      <p>
        {t('learning.volunteering.organization.50.1')}
        <b>{t('learning.volunteering.organization.50.2')}</b>
        {t('learning.volunteering.organization.50.3')}
        <b>{t('learning.volunteering.organization.50.4')}</b>
        {t('learning.volunteering.organization.50.5')}
      </p>
      <p>
        {t('learning.volunteering.organization.51.1')}
        <a href='https://puzzel.org/es/wordseeker/play?p=-Nyzx_6DtisKkPWXDVxK' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.51.2')}</a>
      </p>

      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationPhoto12} style={{ minWidth: 500 }} width={500} height={460} className='mt-4' />
        <div className='pl-4'>
          <br/>
          <p>
            {t('learning.volunteering.organization.52.1')}
            <b>{t('learning.volunteering.organization.52.2')}</b>
            {t('learning.volunteering.organization.52.3')}
            <a href='https://www.youtube.com/watch?v=IdeJLoM295I' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.52.4')}</a>
          </p>
          <p>
            {t('learning.volunteering.organization.53.1')}
            <b>{t('learning.volunteering.organization.53.2')}</b>
            {t('learning.volunteering.organization.53.3')}
            <a href={PATH_VOLUNTEERING_LEARNING_ORGANIZATION + '?page=11'} target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.53.4')}</a>
            {t('learning.volunteering.organization.53.5')}
          </p>
          <p>
            {t('learning.volunteering.organization.54.1')}
            <b>{t('learning.volunteering.organization.54.2')}</b>
            {t('learning.volunteering.organization.54.3')}
            <b>{t('learning.volunteering.organization.54.4')}</b>
            {t('learning.volunteering.organization.54.5')}
            <a href='https://www.rhythmsoftware.com/blog/10-ways-to-recognize-and-reward-your-associations-volunteers' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.54.6')}</a>
            {t('learning.volunteering.organization.54.7')}
          </p>
          <p>
            {t('learning.volunteering.organization.55.1')}
            <b>{t('learning.volunteering.organization.55.2')}</b>
            {t('learning.volunteering.organization.55.3')}
            <b>{t('learning.volunteering.organization.55.4')}</b>
            {t('learning.volunteering.organization.55.5')}
          </p>
        </div>
      </div>

      <br/>
      <p>
        {t('learning.volunteering.organization.56.1')}
        <a href='https://www.youtube.com/watch?v=zq7CfAlgDfI' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.56.2')}</a>
      </p>
    </>
  )

}

export default VolunteeringLearningScreen6;