import { volunteeringVolunteerIllustration15, volunteeringVolunteerImage9 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const VolunteeringLearningScreen9 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-3-2" className='text-volunteering'>{t('learning.volunteering.volunteer.58')}</h4>
      <p>
        {t('learning.volunteering.volunteer.59')}
        <a href="https://europa.eu/europass/digitalskills/screen/home?referrer=epass&route=%2Fsl" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://europa.eu/europass/digitalskills/screen/home?referrer=epass&route=%2Fsl</a>
      </p>
      <div className='text-center'>
        <Image src={volunteeringVolunteerIllustration15} width={400} height={300} />
      </div>
      <br/>
      <br/>
      <h4 id="step-3-3" className='text-volunteering'>{t('learning.volunteering.volunteer.60')}</h4>
      <p>
        <a href="https://test.ikanos.eus/index.php/70" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.61.1')}</a>
        {t('learning.volunteering.volunteer.61.2')}
        <b>{t('learning.volunteering.volunteer.61.3')}</b>.
      </p>
      <br/>
      <h4 id="step-3-4" className='text-volunteering'>{t('learning.volunteering.volunteer.62')}</h4>
      <p>
        {t('learning.volunteering.volunteer.63.1')}
        <a href="https://digital-competence.eu/dc/en/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.63.2')}</a>
        {t('learning.volunteering.volunteer.63.3')}
        <b>{t('learning.volunteering.volunteer.63.4')}</b>
        {t('learning.volunteering.volunteer.63.5')}
      </p>
      <br/>
      <h4 id="step-3-5" className='text-volunteering'>{t('learning.volunteering.volunteer.64')}</h4>
      <p>
        <a href="https://digital-competence.eu/dc/en/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.65.1')}</a>
        {t('learning.volunteering.volunteer.65.2')}
        <b>{t('learning.volunteering.volunteer.65.3')}</b>
        {t('learning.volunteering.volunteer.65.4')}
        <br/>
        {t('learning.volunteering.volunteer.65.5')} (<a href="http://mentep.eun.org/tet-sat" target='_blank' rel='nooppener noreferrer' className="text-volunteering">http://mentep.eun.org/tet-sat</a>)
      </p>
      <br/>
      <h4 id="step-3-6" className='text-volunteering'>{t('learning.volunteering.volunteer.66')}</h4>
      <p>{t('learning.volunteering.volunteer.67')} - <a href="https://www.ted.com/talks/jane_mcgonigal_gaming_can_make_a_better_world?subtitle=en&trigger=0s" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.ted.com/talks/jane_mcgonigal_gaming_can_make_a_better_world?subtitle=en&trigger=0s</a></p>
      <Image src={volunteeringVolunteerImage9} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen9;