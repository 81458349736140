import { Layout } from 'components';
import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { volunteerWhite } from 'assets/icons/index.js';

const VolunteeringLearningSimulation1 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='volunteering-background pt-3 pb-3'>
        <div className='d-flex flex-direction-row align-items-center justify-content-center'>
          <Image src={volunteerWhite} width={24} height={24} className="text-white" />
          <h4 className='pl-2 pb-0 mb-0 text-white'>{t('learning.volunteering.organization.header.1')}</h4>
        </div>
        <p className='pb-0 mb-0 text-white text-center'>{t('learning.volunteering.organization.header.2')}</p>
      </div>
      <Container className='page'>
        <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
          <colgroup>
            <col className='w-10'></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t('learning.volunteering.volunteer.122.1')}</th>
              <th>{t('learning.volunteering.volunteer.122.2')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('learning.volunteering.volunteer.123.1')}</td>
              <td>{t('learning.volunteering.volunteer.123.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.124.1')}</td>
              <td>{t('learning.volunteering.volunteer.124.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.125.1')}</td>
              <td>{t('learning.volunteering.volunteer.125.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.126.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.126.2')}</li>
                  <li>{t('learning.volunteering.volunteer.126.3')}</li>
                  <li>{t('learning.volunteering.volunteer.126.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.127.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.127.2')}</li>
                  <li>{t('learning.volunteering.volunteer.127.3')}</li>
                  <li>{t('learning.volunteering.volunteer.127.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.128.1')}</td>
              <td>
                <ol>
                  <li>{t('learning.volunteering.volunteer.128.2')}</li>
                  <li>{t('learning.volunteering.volunteer.128.3')}</li>
                  <li>{t('learning.volunteering.volunteer.128.4')}</li>
                  <li>{t('learning.volunteering.volunteer.128.5')}</li>
                  <li>{t('learning.volunteering.volunteer.128.6')}</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.129.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.129.2')}</li>
                  <li>{t('learning.volunteering.volunteer.129.3')}</li>
                  <li>{t('learning.volunteering.volunteer.129.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.130.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.130.2')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.131.1')}</td>
              <td>{t('learning.volunteering.volunteer.131.2')}</td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  )

}

export default VolunteeringLearningSimulation1;