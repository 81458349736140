import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppConstants } from 'constant';
import { volunteeringOrganizationIllustration35, volunteeringOrganizationPhoto133 } from 'assets/learning';
import { Image } from 'react-bootstrap';

const { PATH_VOLUNTEERING_LEARNING_ORGANIZATION } = AppConstants;

const VolunteeringLearningScreen20 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32 }}>
        <Image src={volunteeringOrganizationPhoto133} width={'100%'} height={240} style={{ width: '100%', objectFit: 'cover' }} />
      </div>
      <h4 id="step-6-3" className='text-volunteering mt-4'>{t('learning.volunteering.organization.253')}</h4>

      <p>
        {t('learning.volunteering.organization.254.1')}
        <b>{t('learning.volunteering.organization.254.2')}</b>
        {t('learning.volunteering.organization.254.3')}
        <b>{t('learning.volunteering.organization.254.4')}</b>
        {t('learning.volunteering.organization.254.5')}
      </p>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationIllustration35} width={400} height={350} />
        <div className='pl-4'>
          <p>
            {t('learning.volunteering.organization.255.1')}
            <a href='https://www.youtube.com/watch?v=1-ttzJGxyMc' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.255.2')}</a>
          </p>
          <p>
            {t('learning.volunteering.organization.256.1')}
            <b>{t('learning.volunteering.organization.256.2')}</b>
            {t('learning.volunteering.organization.256.3')}
            <b>{t('learning.volunteering.organization.256.4')}</b>
            {t('learning.volunteering.organization.256.5')}
            <b>{t('learning.volunteering.organization.256.6')}</b>
            {t('learning.volunteering.organization.256.7')}
            <a href='https://www.salto-youth.net/tools/otlas-partner-finding/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.256.8')}</a>
            {t('learning.volunteering.organization.256.9')}
          </p>
          <p>
            {t('learning.volunteering.organization.257.1')}
            <b>{t('learning.volunteering.organization.257.2')}</b>
            {t('learning.volunteering.organization.257.3')}
            <a href='https://www.connect-international.org/our-network/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.257.4')}</a>.
          </p>
        </div>
      </div>

      <p>
        {t('learning.volunteering.organization.258.1')}
        <b>{t('learning.volunteering.organization.258.2')}</b>
        {t('learning.volunteering.organization.258.3')}
        <a href='https://digital-youth.org/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.258.4')}</a>.
      </p>

      <div className='text-center mt-5'>
        <button onClick={() => window.open(PATH_VOLUNTEERING_LEARNING_ORGANIZATION + '/simulation-6', '_blank')} className='btn btn-volunteering text-white'>{t('learning.volunteering.organization.simulation.5')}</button>
      </div>
    </>
  )
}

export default VolunteeringLearningScreen20;