import { volunteeringOrganizationIllustration23, volunteeringOrganizationPhoto23 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen12 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32 }}>
        <Image src={volunteeringOrganizationPhoto23} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br />
      <h2 id="step-4">{t('learning.volunteering.organization.143')}</h2>
      <h4 id="step-4-1" className='text-volunteering'>{t('learning.volunteering.organization.144')}</h4>

      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationIllustration23} width={400} height={350} />
        <div className='pl-4 pt-4'>
          <p>{t('learning.volunteering.organization.145')}</p>
          <p>
            {t('learning.volunteering.organization.146.1')}
            <b>{t('learning.volunteering.organization.146.2')}</b>
            {t('learning.volunteering.organization.146.3')}
            <b>{t('learning.volunteering.organization.146.4')}</b>
            {t('learning.volunteering.organization.146.5')}
          </p>
          <ul>
            <li>
              {t('learning.volunteering.organization.147.1')}
              <a href='https://hop.salto-youth.net/courses/youthatwork' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.147.2')}</a>
              {t('learning.volunteering.organization.147.3')}
            </li>
            <li>
              {t('learning.volunteering.organization.148.1')}
              <a href='https://grow.google/intl/uk/courses-and-tools/?category=business&certificate=paid' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.148.2')}</a>
            </li>
            <li>
              {t('learning.volunteering.organization.149.1')}
              <a href='https://www.freecodecamp.org/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.149.2')}</a>
            </li>
          </ul>
        </div>
      </div>
      <p>
        {t('learning.volunteering.organization.150.1')}
        <b>{t('learning.volunteering.organization.150.2')}</b>
        {t('learning.volunteering.organization.150.3')}
        <a href='https://grow.google/intl/uk/courses-and-tools/?category=business&type=live-events' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.150.4')}</a>
      </p>
      <p>
        {t('learning.volunteering.organization.151.1')}
        <a href='https://www.salto-youth.net/tools/toolbox/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.151.2')}</a>
        {t('learning.volunteering.organization.151.3')}
      </p>
      <p>
        {t('learning.volunteering.organization.152')}
        <a href='https://digital-skills-jobs.europa.eu/en/about/digital-volunteers' target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://digital-skills-jobs.europa.eu/en/about/digital-volunteers</a>
      </p>
      <p>
        {t('learning.volunteering.organization.153.1')}
        <b>{t('learning.volunteering.organization.153.2')}</b>
        {t('learning.volunteering.organization.153.3')}
        <b>{t('learning.volunteering.organization.153.4')}</b>
        {t('learning.volunteering.organization.153.5')}
        <a href='https://hop.salto-youth.net/courses/Mentors' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.153.6')}</a>
        {t('learning.volunteering.organization.153.7')}
        <a href='https://www.mentor.backslash.es/es/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.153.8')}</a>
        {t('learning.volunteering.organization.153.9')}
      </p>
      <p>
        {t('learning.volunteering.organization.154.1')}
        <b>{t('learning.volunteering.organization.154.2')}</b>
        {t('learning.volunteering.organization.154.3')}
        <b>{t('learning.volunteering.organization.154.4')}</b>
        {t('learning.volunteering.organization.154.5')}
      </p>

    </>
  )
}

export default VolunteeringLearningScreen12;