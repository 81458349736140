import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { Empty, FormInput, Layout, Loader } from 'components';
import { invitationPurple, office, volunteerWhite } from 'assets/icons';
import { AppConstants, ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import VolunteeringFunctionNewInvitation from './modal/new';
import { AppUtils, ModelUtils } from 'utils';
import VolunteeringFunctionEditMemberData from './modal/edit';
import VolunteeringFunctionDeleteMemberData from './modal/delete';
import VolunteeringFunctionResendInvitation from './modal/resend';
import VolunteeringFunctionWithdrawInvitation from './modal/withdraw';

const { PATH_VOLUNTEERING_SPACE, PATH_VOLUNTEERING_LEARNING_ORGANIZATION, PATH_VOLUNTEERING_LEARNING_VOLUNTEER } = AppConstants;
const { API_METHOD_GET, API_URL_VOLUNTEERING_INVITATIONS } = ServerConstants;

const FunctionVolunteeringScreen = () => {

  const { t } = useTranslation();
  const invitationFetch = useFetch(API_METHOD_GET);
  const [invitationsFiltered, setInvitationsFiltered] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewModal, setShowNewModal] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [reload, setReload] = useState(0);
  const invitations = useRef([]);

  useEffect(() => {
    if (AppUtils.isUserOrganization()) {
      invitationFetch.setFetchUrl(API_URL_VOLUNTEERING_INVITATIONS);
      invitationFetch.forceFetchData();
    }
  }, []);

  useEffect(() => {
    if (reload > 0) {
      setClaimed(false);
      invitationFetch.forceFetchData();
    }
  }, [reload])

  useEffect(() => {
    if (invitationFetch.response) {
      invitations.current = invitationFetch.response.data.map(el => ModelUtils.convertVolunteeringInvitationModelB2F(el));
      setInvitationsFiltered(invitations.current.filter(el => el.accepted === claimed));
    }
  }, [invitationFetch.response]);


  useEffect(() => {
    let array = invitations.current.filter(el => el.accepted === claimed);
    if (searchTerm && searchTerm.trim() !== '') {
      array = array.filter(el => el.fullName.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    setInvitationsFiltered(array);
  }, [searchTerm, claimed]);

  return (
    <Layout>
      <div className="function-wallpaper volunteering">
        <Container style={{ height: '100%', width: '100%' }}>
          <div className="d-flex flex-direction-row justify-content-around align-items-center" style={{ height: '100%' }}>
            <Image src={volunteerWhite} width={'50%'} className='d-none d-lg-block mr-5' />
            <div className='ml-5'>
              <div className="d-flex flex-direction-row align-items-center mb-3">
                <Image src={volunteerWhite} />
                <span className="ml-3">{t('functions.volunteering.title')}</span>
              </div>
              <h2>{t('functions.volunteering.header.title')}</h2>
              <p>{t('functions.volunteering.header.description')}</p>
              <Button variant='secondary' disabled={!AppUtils.isUserLogged()} onClick={() => window.location.href = PATH_VOLUNTEERING_SPACE} className='mr-2'>{t('functions.volunteering.header.action.virtual')}</Button>
              <Button variant='secondary' onClick={() => window.location.href = PATH_VOLUNTEERING_LEARNING_ORGANIZATION} className='mr-2'>{t('functions.volunteering.goto.organization')}</Button>
              <Button variant='secondary' onClick={() => window.location.href = PATH_VOLUNTEERING_LEARNING_VOLUNTEER}>{t('functions.volunteering.goto.volunteers')}</Button>
            </div>
          </div>
        </Container>
      </div>
      {AppUtils.isUserOrganization() ?
        <Container className="mb-5">
          <VolunteeringFunctionNewInvitation show={showNewModal} onDismiss={() => setShowNewModal(false)} onSubmit={() => { setShowNewModal(false); setReload(old => old + 1);}} />
          <div className="d-flex flex-direction-row align-items-center mt-5">
            <Image src={invitationPurple} />
            <h3 className="text-volunteering mx-4 mb-0">{t('functions.volunteering.invitations.title')}</h3>
            <Button variant="volunteering" className="px-3" onClick={() => setShowNewModal(true)}><FontAwesomeIcon icon={'plus'} /></Button>
          </div>
          {invitationFetch.loading ? <div className="mt-2"><Loader /></div> :
            invitations.current.length === 0 ? <Empty messageKey={t('functions.volunteering.invitations.empty.general')} /> :
              <>
                <div className="d-flex flex-direction-row justify-content-between align-items-center mb-3">
                  <div className="mb-3">
                    <div className="d-flex flex-direction-row align-items-center">
                      <span className={"pt-1" + (claimed === false ? " text-volunteering" : "")}>{t('functions.volunteering.invitations.claimed.no')}</span>
                      <Form.Switch
                        className="form-switch-purple mx-2"
                        onClick={() => setClaimed(old => !old)}
                        value={claimed}
                      />
                      <span className={"pt-1" + (claimed === true ? " text-volunteering" : "")}>{t('functions.volunteering.invitations.claimed.yes')}</span>
                    </div>
                  </div>
                  <FormInput id="searchTerm" placeholder={t('functions.volunteering.invitations.search')} onChange={(e) => setSearchTerm(e.target.value)} className="mb-0" />
                </div>
                <div className="mb-3">
                  {invitationsFiltered.length === 0 ? <Empty messageKey={t('functions.volunteering.invitations.empty.' + (claimed ? 'accepted' : 'invited'))} /> :
                    invitationsFiltered.map(el => claimed ? <ClaimedItem el={el} reload={() => setReload((old) => old + 1)} t={t} /> : <NotClaimedItem el={el} reload={() => setReload((old) => old + 1)} t={t} />)}
                </div>
              </>
          }
        </Container>
        : undefined}
    </Layout >
  )
}

const NotClaimedItem = ({ el, reload, t }) => {

  const [showResendModal, setShowResendModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  return (
    <div key={'invitation-' + el.invitationId}>
      <VolunteeringFunctionResendInvitation el={el} show={showResendModal} onDismiss={() => setShowResendModal(false)} onSuccess={() => { setShowResendModal(false); reload() }} />
      <VolunteeringFunctionWithdrawInvitation el={el} show={showWithdrawModal} onDismiss={() => { setShowWithdrawModal(false) }} onSuccess={() => { setShowWithdrawModal(false); reload() }} />
      <div className="invitation" >
        <div className="invitation-item">
          <span>{el.fullName}</span>
          <span className="mx-2">&#x2022;</span>
          <span className="muted">{moment(el.date).utc(true).local().fromNow()}</span>
        </div>
        <div>
          <Button variant="secondary" className="mr-2" onClick={() => setShowResendModal(true)}>{t('functions.volunteering.invitations.resend.action')}</Button>
          <Button variant="danger" onClick={() => setShowWithdrawModal(true)}>{t('functions.volunteering.invitations.withdraw.action')}</Button>
        </div>
      </div>
    </div>
  )
}

const ClaimedItem = ({ el, reload, t }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div key={'invitation-' + el.invitationId}>
      <VolunteeringFunctionEditMemberData el={el} show={showEditModal} onDismiss={() => setShowEditModal(false) } onSuccess={() => { setShowEditModal(false); reload(); }} />
      <VolunteeringFunctionDeleteMemberData el={el} show={showDeleteModal} onDismiss={() => setShowDeleteModal(false) } onSuccess={() => { setShowDeleteModal(false); reload(); }} />
      <div className="invitation">
        <div className="invitation-item">
          <span>{el.fullName}</span>
          <span className="mx-2">&#x2022;</span>
          <span>{el.position}</span>
          <span className="mx-2">&#x2022;</span>
          <span className="muted">{moment(el.date).utc(true).local().fromNow()}</span>
        </div>
        <div>
          <Button variant="secondary" className="mr-2" onClick={() => setShowEditModal(true)}>{t('functions.volunteering.invitations.edit.action')}</Button>
          <Button variant="danger" onClick={() => setShowDeleteModal(true)}>{t('functions.volunteering.invitations.delete.action')}</Button>
        </div>
      </div>
    </div>
  )
}


export default FunctionVolunteeringScreen;