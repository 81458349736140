import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppConstants } from 'constant';
import { volunteeringOrganizationIllustration25 } from 'assets/learning';
import { Image } from 'react-bootstrap';

const { PATH_VOLUNTEERING_LEARNING_ORGANIZATION } = AppConstants;

const VolunteeringLearningScreen14 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-3" className='text-volunteering'>{t('learning.volunteering.organization.167')}</h4>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationIllustration25} width={400} height={350} />
        <div className='pl-4 pt-4'>
          <p>{t('learning.volunteering.organization.168')}</p>
          <p>
            {t('learning.volunteering.organization.169.1')}
            <b>{t('learning.volunteering.organization.169.2')}</b>
            {t('learning.volunteering.organization.169.3')}
          </p>
          <p>
            {t('learning.volunteering.organization.170.1')}
            <b>{t('learning.volunteering.organization.170.2')}</b>
            {t('learning.volunteering.organization.170.3')}
            <b>{t('learning.volunteering.organization.170.4')}</b>
            {t('learning.volunteering.organization.170.5')}
            <a href='https://www.lepaya.com/blog/advantages-of-a-buddy-system' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.170.6')}</a>.
          </p>
        </div>
      </div>
      <p>
        {t('learning.volunteering.organization.171.1')}
        <b>{t('learning.volunteering.organization.171.2')}</b>
        {t('learning.volunteering.organization.171.3')}
        <a href='https://www.hoppier.com/blog/virtual-team-building-activities' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.171.4')}</a>
        {t('learning.volunteering.organization.171.5')}
        <a href='https://www.atlassian.com/blog/teamwork/5-minute-team-building-activities-for-virtual-teams' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.171.6')}</a>
        {t('learning.volunteering.organization.171.7')}
      </p>
      <div className='text-center mt-5'>
        <button onClick={() => window.open(PATH_VOLUNTEERING_LEARNING_ORGANIZATION + '/simulation-4', '_blank')} className='btn btn-volunteering text-white'>{t('learning.volunteering.organization.simulation.4')}</button>
      </div>
    </>
  )
}

export default VolunteeringLearningScreen14;