import { Layout } from 'components';
import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { volunteerWhite } from 'assets/icons/index.js';

const VolunteeringLearningSimulation6 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='volunteering-background pt-3 pb-3'>
        <div className='d-flex flex-direction-row align-items-center justify-content-center'>
          <Image src={volunteerWhite} width={24} height={24} className="text-white" />
          <h4 className='pl-2 pb-0 mb-0 text-white'>{t('learning.volunteering.organization.header.1')}</h4>
        </div>
        <p className='pb-0 mb-0 text-white text-center'>{t('learning.volunteering.organization.header.2')}</p>
      </div>
      <Container className='page'>
        <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
          <colgroup>
            <col className='w-10'></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t('learning.volunteering.organization.259.1')}</th>
              <th>{t('learning.volunteering.organization.259.2')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('learning.volunteering.organization.260.1')}</td>
              <td>{t('learning.volunteering.organization.260.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.261.1')}</td>
              <td>{t('learning.volunteering.organization.261.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.262.1')}</td>
              <td>{t('learning.volunteering.organization.262.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.263.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.263.2')}</li>
                  <li>{t('learning.volunteering.organization.263.3')}<a href='https://app.hyhyve.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.263.4')}</a></li>
                  <li>
                    <a href='https://dotstorming.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.263.5')}</a>
                    {t('learning.volunteering.organization.263.6')}
                    <a href='https://miro.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.263.7')}</a>
                    {t('learning.volunteering.organization.263.8')}
                  </li>
                  <li>
                    {t('learning.volunteering.organization.263.9')}
                    <a href='https://www.canva.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.263.10')}</a>
                  </li>
                  <li><a href='https://www.iloveimg.com/meme-generator' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.263.11')}</a></li>
                  <li>{t('learning.volunteering.organization.263.12')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.264.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.264.2')}</li>
                  <li>{t('learning.volunteering.organization.264.3')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.265.1')}</td>
              <td>
                <ul>
                  <li>
                    {t('learning.volunteering.organization.265.2')}
                    <a href='https://drive.google.com/file/d/1xfoMPIcLIl_FWIKPLK8r0I7Dv81IqP00/view?usp=drive_link' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.265.3')}</a>
                    {t('learning.volunteering.organization.265.4')}
                    <a href='https://www.canva.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.265.5')}</a>
                    {t('learning.volunteering.organization.265.6')}
                    <a href='https://www.canva.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.265.7')}</a>
                    {t('learning.volunteering.organization.265.8')}
                  </li>
                  <li>
                    {t('learning.volunteering.organization.265.9')}
                    <br/>
                    {t('learning.volunteering.organization.265.10')}
                    <br/>
                    {t('learning.volunteering.organization.265.11')}
                  </li>
                  <li>{t('learning.volunteering.organization.265.12')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.266.1')}</td>
              <td>{t('learning.volunteering.organization.266.2')}</td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  )

}

export default VolunteeringLearningSimulation6;