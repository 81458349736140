import { Layout } from 'components';
import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { volunteerWhite } from 'assets/icons/index.js';

const VolunteeringLearningSimulation5 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='volunteering-background pt-3 pb-3'>
        <div className='d-flex flex-direction-row align-items-center justify-content-center'>
          <Image src={volunteerWhite} width={24} height={24} className="text-white" />
          <h4 className='pl-2 pb-0 mb-0 text-white'>{t('learning.volunteering.organization.header.1')}</h4>
        </div>
        <p className='pb-0 mb-0 text-white text-center'>{t('learning.volunteering.organization.header.2')}</p>
      </div>
      <Container className='page'>
        <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
          <colgroup>
            <col className='w-10'></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t('learning.volunteering.organization.187.1')}</th>
              <th>{t('learning.volunteering.organization.187.2')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('learning.volunteering.organization.188.1')}</td>
              <td>{t('learning.volunteering.organization.188.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.189.1')}</td>
              <td>{t('learning.volunteering.organization.189.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.190.1')}</td>
              <td>{t('learning.volunteering.organization.190.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.191.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.191.2')}</li>
                  <li><a href='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG0tWvObIlNtYrhmFetsXQlRrOQlcuXQQehLTpjhcJL7oZoUoQENyhdLDiZWFnKPOKZAI&usqp=CAU' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.191.3')}</a></li>
                  <li><a href='https://drive.google.com/file/d/16DpowNX80G9sN2pOU769GTl8JIGXSz8P/view?usp=drive_link' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.191.4')}</a></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.192.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.192.2')}</li>
                  <li>{t('learning.volunteering.organization.192.3')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.193.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.193.2')}</li>
                  <li>{t('learning.volunteering.organization.193.3')}</li>
                  <li>{t('learning.volunteering.organization.193.4')}</li>
                  <li>{t('learning.volunteering.organization.193.5')}</li>
                  <li>{t('learning.volunteering.organization.193.6')}</li>
                  <li>{t('learning.volunteering.organization.193.7')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.194.1')}</td>
              <td>{t('learning.volunteering.organization.194.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.195.1')}</td>
              <td>
                {t('learning.volunteering.organization.195.2')}
                <a href='https://mentorup-project.eu/wp-content/uploads/2023/01/Mentor-Up-Manual.pdf' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.195.3')}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  )

}

export default VolunteeringLearningSimulation5;