import { volunteeringOrganizationIllustration10, volunteeringOrganizationPhoto10 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-1-4" className='text-volunteering'>{t('learning.volunteering.organization.39')}</h4>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringOrganizationIllustration10} width={400} height={350} />
        <div className='pt-4 pl-4'>
          <p>{t('learning.volunteering.organization.40')}</p>
          <p>{t('learning.volunteering.organization.41')}</p>
          <p>{t('learning.volunteering.organization.42')}</p>
        </div>
      </div>
      <p>{t('learning.volunteering.organization.43')}</p>
      <p>{t('learning.volunteering.organization.44')}</p>
      <br/>
      <Image src={volunteeringOrganizationPhoto10} width={'100%'} height={400} style={{ objectFit: 'cover' }} />
    </>
  )

}

export default VolunteeringLearningScreen5;