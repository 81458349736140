import { volunteeringOrganizationPhoto1, volunteeringOrganizationPhoto10, volunteeringOrganizationPhoto7 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen17 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-5-3" className='text-volunteering'>{t('learning.volunteering.organization.214')}</h4>

      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringOrganizationPhoto1} width={300} height={160} style={{ minWidth: 600, objectFit: 'contain' }} />
        <div className='pl-4'>
          <b>{t('learning.volunteering.organization.215.1')}</b>
          {t('learning.volunteering.organization.215.2')}
          <b>{t('learning.volunteering.organization.215.3')}</b>
          {t('learning.volunteering.organization.215.4')}
          <b>{t('learning.volunteering.organization.215.5')}</b>
          {t('learning.volunteering.organization.215.6')}
          <b>{t('learning.volunteering.organization.215.7')}</b>
          {t('learning.volunteering.organization.215.8')}
        </div>
      </div>
      <br/>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringOrganizationPhoto10} width={300} height={160} style={{ minWidth: 600, objectFit: 'contain' }} />
        <div className='pl-4'>
          <b>{t('learning.volunteering.organization.216.1')}</b>
          {t('learning.volunteering.organization.216.2')}
          <b>{t('learning.volunteering.organization.216.3')}</b>
          {t('learning.volunteering.organization.216.4')}
          <b>{t('learning.volunteering.organization.216.5')}</b>
          {t('learning.volunteering.organization.216.6')}
          <b>{t('learning.volunteering.organization.216.7')}</b>
          {t('learning.volunteering.organization.216.8')}
        </div>
      </div>
      <br/>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringOrganizationPhoto7} width={300} height={160} style={{ minWidth: 600, objectFit: 'contain' }} />
        <div className='pl-4'>
          {t('learning.volunteering.organization.217.1')}
          <b>{t('learning.volunteering.organization.217.2')}</b>
          {t('learning.volunteering.organization.217.3')}
          <a href='https://www.connect-international.org/news/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.217.4')}</a>
          {t('learning.volunteering.organization.217.5')}
          <a href='https://www.youtube.com/@galaxydigitalvolunteermana4795' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.217.6')}</a>
        </div>
      </div>

      <br/>
      <p>
        {t('learning.volunteering.organization.219.1')}
        <b>{t('learning.volunteering.organization.219.2')}</b>
        {t('learning.volunteering.organization.219.3')}
      </p>
    </>
  )
}

export default VolunteeringLearningScreen17;