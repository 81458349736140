import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { logoEU, logoFooter } from 'assets/icons';
import { AppConstants } from 'constant';

const {
  PATH_HOME, PATH_NEWS, PATH_COMMUNITY, PATH_FAQ, PATH_PRIVACY_AND_POLICY,
  PATH_ENGAGE, PATH_EMPOWER, PATH_CONNECT
} = AppConstants;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="light-gray-background footer">
      <Container className="mt-5">
        <Row>
          <Col className='col-5'>
            <div className="d-flex flex-direction-row align-items-center mb-4">
              <div><Image src={logoFooter}/></div>
              <div className='px-4 py-0'><Image src={logoEU}/></div>
            </div>
            <p><small>{t('footer.description')}</small></p>
            <div className="d-flex flex-direction-row align-items-center pt-3">
              <a href="/#" className="social-icon"><FontAwesomeIcon icon={['fab', 'instagram']} size={'lg'} color='black' /></a>
              <a href="/#" className="social-icon"><FontAwesomeIcon icon={['fab', 'linkedin-in']} size={'lg'} color='black' /></a>
              <a href="/#" className="social-icon"><FontAwesomeIcon icon={['fab', 'facebook-f']} size={'lg'} color='black' /></a>
              <a href="/#" className="social-icon"><FontAwesomeIcon icon={['fab', 'twitter']} size={'lg'} color='black' /></a>
              <a href="/#" className="social-icon"><FontAwesomeIcon icon={['fab', 'youtube']} size={'lg'} color='black' /></a>
            </div>
          </Col>
          <Col className='col-2'>
            <p className="mb-1"><a href={PATH_HOME} className="muted"><small>{t('navbar.home')}</small></a></p>
            <p className="mb-1"><a href={PATH_NEWS} className="muted"><small>{t('navbar.news')}</small></a></p>
            {/* <p className="mb-1"><a href={PATH_ABOUT} className="muted"><small>{t('navbar.about')}</small></a></p> */}
            <p className="mb-1"><a href={PATH_COMMUNITY} className="muted"><small>{t('navbar.community')}</small></a></p>
            <p className="mb-1"><a href={PATH_FAQ} className="muted"><small>{t('navbar.faq')}</small></a></p>
            <p className="mb-1"><a href="https://e-ngagement.org" target="_blank" rel="noreferrer" className="muted"><small>{t('navbar.contact')}</small></a></p>
          </Col>
          <Col className='col-2'>
            <p><b>{t('functions.title')}</b></p>
            <p className="mb-1"><a href={PATH_ENGAGE} className="muted"><small>{t('functions.engage.title')}</small></a></p>
            <p className="mb-1"><a href={PATH_EMPOWER} className="muted"><small>{t('functions.empower.title')}</small></a></p>
            <p className="mb-1"><a href={PATH_CONNECT} className="muted"><small>{t('functions.connect.title')}</small></a></p>
          </Col>
          <Col className='col-3'>
            <p><b>{t('navbar.contact')}</b></p>
            <p className="mb-1"><small>digitalyouplatform@gmail.com</small></p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className='col-7'>
            <p><small className="muted">{t('footer.copyright')}</small></p>
          </Col>
          <Col className='col-5'>
            <div className="d-flex flex-direction-row align-items-end justify-content-end">
              <a href={PATH_PRIVACY_AND_POLICY} className="muted"><small>{t('footer.privacy')}</small></a>
              <a href="/terms" className="muted mx-4"><small>{t('footer.terms')}</small></a>
              <a href="/licence" className="muted"><small>{t('footer.licence')}</small></a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer;